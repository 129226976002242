import type { ReactNode } from 'react';
import { SizeTypes } from '~constants/components';

export interface InputStylesType {
  defaultProps?: {
    size?: SizeTypes;
    label?: string;
    errors?: string[];
    prefix?: ReactNode;
    className?: string;
  };
  valid?: {
    sizes?: string[];
  };
  styles: {
    base: {
      container?: {
        position: string;
        width: string;
        height: string;
        minWidth: string;
      };
      input?: Record<string, string> | undefined;
      prefix?: Record<string, string>;
      suffix?: Record<string, string>;
      label?: Record<string, string>;
      errorText?: Record<string, string>;
    };
    variants: {
      base: {
        inputWithPrefix: {
          pr: string;
        };
      };
      sizes: Record<
        SizeTypes,
        {
          input: {
            fontSize: string;
            height: string;
          };
          prefix: {
            width: string;
          };
          prefixWithLabel: {
            top: string;
          };
        }
      >;
      label: {
        display: string;
        gap: string;
      };
      errors: {
        input: {
          mb: string;
          ring: string;
        };
      };
      readOnly: {
        input: {
          ring: string;
        };
      };
    };
  };
}

export const input: InputStylesType = {
  defaultProps: {
    className: '',
  },
  valid: {
    sizes: Object.values(SizeTypes),
  },
  styles: {
    base: {
      container: {
        position: 'relative',
        width: 'w-full',
        height: 'h-full',
        minWidth: 'min-w-[200px]',
      },
      input: {
        width: 'w-full',
        height: 'h-full',
        bg: 'bg-evaluated',
        border: 'border border-oxford',
        color: 'text-white',
        colorSecondary: 'text-grey',
        fontSize: 'text-base',
        fontWeight: 'font-medium',
        padding: 'px-5',
        ring: 'ring-1 ring-transparent focus:ring-white',
        outline: 'outline outline-offset-0 outline-none',
        disabled:
          'disabled:ring-0 disabled:cursor-not-allowed disabled:opacity-80',
        placeholder:
          'placeholder:font-normal placeholder:text-gray/30 placeholder:text-base',
        borderRadius: 'rounded-lg',
      },
      prefix: {
        display: 'flex justify-center items-center',
        position: 'absolute left-1 w-6 h-10 text-base',
        flex: 'flex-none',
        color: 'text-white',
        colorSecondary: 'text-white',
      },
      suffix: {
        size: 'h-10 w-4',
        display: 'flex items-center justify-center',
        flex: 'flex-none',
        position: 'absolute',
        color: 'text-white',
        right: 'right-5',
        cursor: 'cursor-pointer',
      },
      label: {
        fontSize: 'text-sm',
        color: 'text-white',
      },
      errorText: {
        fontSize: 'text-sm',
        color: 'text-danger',
      },
    },
    variants: {
      base: {
        inputWithPrefix: {
          pr: 'pl-14',
        },
      },
      sizes: {
        [SizeTypes.Small]: {
          input: {
            height: 'h-[30px] px-2',
            fontSize: 'text-xs',
          },
          prefix: {
            width: 'w-[30px]',
          },
          prefixWithLabel: {
            top: 'top-[62%]',
          },
        },
        [SizeTypes.Medium]: {
          input: {
            height: 'h-10 px-4',
            fontSize: 'text-sm',
          },
          prefix: {
            width: 'w-10',
          },
          prefixWithLabel: {
            top: 'top-[60%]',
          },
        },
        [SizeTypes.Large]: {
          input: {
            height: 'h-[50px]',
            fontSize: 'text-base',
          },
          prefix: {
            width: 'w-[50px]',
          },
          prefixWithLabel: {
            top: 'top-[58%]',
          },
        },
      },
      label: {
        display: 'flex flex-col',
        gap: 'gap-y-2.5',
      },
      errors: {
        input: {
          mb: 'mb-[5px]',
          ring: 'ring-danger focus:ring-danger',
        },
      },
      readOnly: {
        input: {
          ring: 'ring-transparent focus:ring-transparent',
        },
      },
    },
  },
};

export default input;
