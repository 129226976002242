import { ButtonColors } from '~constants/components';

const buttonGhost = {
  [ButtonColors.Default]: {
    border: 'border border-secondary',
    color: 'text-secondary',
  },
  [ButtonColors.Primary]: {
    border: 'border border-primary',
    color: 'text-primary',
  },
  [ButtonColors.Success]: {
    border: 'border border-success',
    color: 'text-success',
  },
  [ButtonColors.Danger]: {
    border: 'border border-danger',
    color: 'text-danger',
  },
  [ButtonColors.Transparent]: {
    border: 'border border-transparent',
    color: 'text-primary',
  },
};

export default buttonGhost;
