import Button from './components/button';
import Input from './components/input';
import Dialog from './components/dialog';
import Note from './components/note';
import Tooltip from './components/tooltip';
import Pagination from './components/pagination';

const themes = {
  button: Button,
  input: Input,
  dialog: Dialog,
  note: Note,
  tooltip: Tooltip,
  pagination: Pagination,
};

export default themes;
