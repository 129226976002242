export enum SizeTypes {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum ButtonColors {
  Default = 'default',
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Transparent = 'transparent',
}

export enum ButtonVariants {
  Fill = 'fill',
  Ghost = 'ghost',
}
