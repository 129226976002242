import { NoteColor } from '~constants/etc';

const NoteColors: object = {
  [NoteColor.Default]: {
    background: 'bg-[#59553E]',
    text: 'text-primary',
    border: 'border-primary',
  },
  [NoteColor.Success]: {
    background: 'bg-[#3E5949]',
    text: 'text-success',
    border: 'border-success',
  },
  [NoteColor.Danger]: {
    background: 'bg-[#593E3E]',
    text: 'text-danger',
    border: 'border-danger',
  },
  [NoteColor.Ghost]: {
    background: 'bg-dark',
    text: 'text-white',
    border: 'border-white',
  },
};

export default NoteColors;
